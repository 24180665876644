<template lang="pug">
main(v-if='response', data-view)
  section.hero
    prismic-embed.hero__main-video(:field='response.data.hero_video')
    .hero__meta
      prismic-rich-text.hero__meta__title(:field='response.data.home_title')
      prismic-rich-text(:field='response.data.hero_text')
      .hero__meta__buttons
        common-button.hero__meta__buttons__button(
          label='Nos programmes',
          variant='orange',
          :path='{ name: "Courses" }'
        )
        common-button.hero__meta__buttons__button(
          label='Découvrez les coachs',
          variant='orange',
          :path='{ name: "Coach" }'
        )

  section.explanations
    .explanations__data
      span.explanations__data__tag Blast
      prismic-rich-text.explanations__data__title(:field='response.data.second_section_title')
      prismic-rich-text.explanations__data__text(:field='response.data.second_section_text')
    .explanations__results
      .explanations__results__result(v-for='result in response.data.results')
        .explanations__results__result__illustration
          prismic-image(:field='result.illustration')
        span.explanations__results__result__text {{ result.label[0].text }}

  section.courses
    .courses__head
      span.courses__head__tag QUELQUES PROGRAMMES
      router-link.courses-link(:to='{ name: "Courses" }') 
        span Voir tous
        <svg width="24" height="12" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.937529 5.06251H20.7918L17.5029 1.78951C17.1359 1.42426 17.1345 0.830683 17.4997 0.463699C17.865 0.0966682 18.4586 0.0953092 18.8256 0.460512L23.7242 5.33551C23.7245 5.33579 23.7248 5.33612 23.7251 5.3364C24.0911 5.70165 24.0923 6.29715 23.7252 6.66362C23.7248 6.6639 23.7246 6.66422 23.7243 6.66451L18.8257 11.5395C18.4587 11.9047 17.8651 11.9034 17.4998 11.5363C17.1346 11.1693 17.136 10.5758 17.503 10.2105L20.7918 6.93751H0.937529C0.419748 6.93751 2.86102e-05 6.51779 2.86102e-05 6.00001C2.86102e-05 5.48223 0.419748 5.06251 0.937529 5.06251Z" fill="white"/>
        </svg>
    h3.courses__title Sélectionnez un programme pour en savoir plus

    .courses__wrapper
      course-abstract-card(v-for='course in courses', :course='course', :key='course.id')

  section.testimonies
    .testimonies__data
      prismic-rich-text.testimonies__data__tag(:field='response.data.testimonies_title')
      q.testimonies__data__quote {{ response.data.helping[0].text }}
      p.testimonies__data__text {{ response.data.testimonies_description[0].text }}
    .testimonies__tokens
      .testimonies__tokens__token(v-for='testimony in response.data.testimonies')
        .testimonies__tokens__token__quote-wrapper
          q {{ `"${testimony.text[0].text}"` }}
        span.testimonies__tokens__token__author-name {{ testimony.author_name[0].text }}
        span.testimonies__tokens__token__author-job {{ testimony.author_job[0].text }}
        span.testimonies__tokens__token__author-age {{ `${testimony.author_age[0].text} ans` }}

  section.help
    h5.help__title {{ response.data.help_text[0].text }}
    common-button.help__button(
      :label='response.data.send_mail_text[0].text',
      variant='orange',
      :path='{ href: "mailto:blast@blast-online.com" }',
      isLink
    )

  free-trial-modal(ref='freeTrialModal', :coursesToSelect='courses')
</template>

<script>
export default {
  name: 'Home',
  components: {
    CourseAbstractCard: () => import('@/components/CourseAbstractCard'),
    CommonButton: () => import('@/components/CommonButton'),
    MissionCard: () => import('@/components/MissionCard'),
    FreeTrialModal: () => import('@/components/FreeTrialModal')
  },
  metaInfo() {
    return {
      title: this.response && this.response.data.title,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.response && this.response.data.description
        }
      ]
    }
  },
  data() {
    return {
      response: null,
      courses: null
    }
  },
  computed: {
    relatedCourseIds() {
      return this.response && this.response.data.body.map((course) => course.primary.related_course.id)
    }
  },
  created() {
    this.getContent()
  },
  methods: {
    async getContent() {
      const response = await this.$prismic.client.getSingle('home')
      this.response = response
      this.getRelatedCourses()
    },
    async getRelatedCourses() {
      const response = await this.$prismic.client.getByIDs(this.relatedCourseIds)
      this.courses = response.results
    },
    showFreeTrialModal() {
      this.$refs.freeTrialModal.show()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
@import '@/styles/mixins';
@import '@/styles/vars';

.hero {
  display: flex;
  flex-direction: column;
  margin: 0 -1.5rem;
  padding: 1.5rem;
  background-color: rgba($green, 0.4);
  border-radius: 0 0 20px 20px;
  margin-bottom: 3rem;

  &__meta {
    &__title {
      font-size: 2rem;
      line-height: 2rem;
      color: $darkBlue;
      margin-bottom: 1rem;
      text-transform: uppercase;
      &::v-deep {
        h1 {
          font-family: 'Bebas Neue', sans-serif;
          font-weight: 500;
        }
      }
    }

    &__buttons {
      margin-bottom: 1rem;
      &__button {
        margin-bottom: 1rem;
        // &:nth-child(2) {
        //   display: none;
        // }
      }
    }
  }

  &__main-video {
    margin-bottom: 1rem;
    height: 250px;
    margin-right: -1.5rem;
    border-radius: 20px 0 0 20px;
    overflow: hidden;

    &::v-deep {
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include medium-screen {
    position: relative;
    flex-direction: row;
    margin: 0;
    margin-bottom: 3rem;
    padding: 2rem 0;
    background-color: transparent;
    align-items: center;

    &__main-video {
      height: 300px;
      flex-grow: 1;
      order: 2;
      margin: 0;
      margin-right: -$mainHorizontalPaddingMedium;
    }

    &__meta {
      box-sizing: border-box;
      flex-basis: 60%;
      padding-right: 4rem;

      &__title {
        font-size: 3rem;
        line-height: 4rem;
      }

      &__buttons {
        display: flex;

        &__button {
          margin-right: 1rem;
          // &:nth-child(2) {
          //   display: block;
          // }
        }
      }
    }

    &::after {
      content: '';
      height: 100%;
      width: 80%;
      position: absolute;
      background-color: rgba($green, 0.4);
      margin: -2rem 0 -2rem (-2rem -$mainHorizontalPaddingMedium);
      z-index: -1;
      border-radius: 0 0 20px 0;
    }
  }

  @include large-screen {
    &__main-video {
      height: 350px;
      margin-right: -$mainHorizontalPaddingLarge;
    }

    &::after {
      margin: -2rem 0 -2rem (-2rem -$mainHorizontalPaddingLarge);
    }
  }
}

.explanations {
  display: flex;
  flex-direction: column;
  margin-bottom: 7rem;

  &__data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__tag {
      color: $orange;
      font-size: 1.5rem;
      margin-bottom: 1rem;
      display: block;
      letter-spacing: 4px;

      &::v-deep {
        font-family: 'Bebas Neue', sans-serif;
      }
    }

    &__title {
      color: $darkGrey;
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  &__results {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 500;

    &__result {
      width: 70px;
      min-width: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__illustration {
        width: 100%;
        height: 70px;
        border-radius: 50%;
        box-shadow: 0px 3px 16px 0px rgba(49, 49, 49, 0.2);
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        img {
          width: 100%;
        }
      }
      &__text {
        font-family: 'Libre Franklin', sans-serif;
        font-weight: 600;
      }
    }
  }

  @include medium-screen {
    flex-direction: row;

    &__results {
      width: 300px;
      min-width: 300px;
      justify-content: space-evenly;
      align-content: center;
      &__result {
        margin: 0.5rem;
        width: 120px;
        min-width: 120px;

        &__illustration {
          height: 120px;
        }
      }
    }
  }
}

.courses {
  background-color: $darkBlue;
  margin: -$mainHorizontalPadding;
  position: relative;
  padding: 2rem 1rem;
  margin-bottom: 5rem;

  &__head {
    color: $white;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;

    &__tag {
      font-size: 1.5rem;
      letter-spacing: 4px;

      &::v-deep {
        font-family: 'Bebas Neue', sans-serif;
      }
    }
  }

  &__title {
    color: $darkGrey;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: $white;
  }

  &__free-trial-button {
    margin: 2rem auto;
    font-size: 20px !important;
  }

  &__wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  @include medium-screen {
    background-color: transparent;
    margin: 0;
    margin-bottom: 5rem;

    &::after {
      top: 0;
      right: 0;
      content: '';
      height: 100%;
      width: calc(105% + #{$mainHorizontalPaddingMedium});
      position: absolute;
      background-color: $darkBlue;
      margin: 0 (-$mainHorizontalPaddingMedium) 0 0;
      z-index: -1;
      border-radius: 20px 0 0px 20px;
    }
  }

  @include large-screen {
    &::after {
      width: calc(105% + #{$mainHorizontalPaddingLarge});
      margin: 0 (-$mainHorizontalPaddingLarge) 0 0;
    }
  }

  .courses-link {
    color: $white;
    text-decoration: none;
    font-family: 'Libre Franklin', sans-serif;
    font-weight: 700;
    display: flex;
    align-items: center;

    span {
      margin-right: 0.5rem;
    }
  }
}

.testimonies {
  &__data {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    > * {
      margin-bottom: 1rem;
    }

    &__tag {
      font-size: 1.5rem;
      letter-spacing: 4px;
      color: $orange;
      &::v-deep {
        h3 {
          font-family: 'Bebas Neue', sans-serif;
          font-weight: normal;
        }
      }
    }

    &__quote {
      font-size: 1.5rem;
      text-transform: uppercase;
      font-family: 'Hind Vadodara', sans-serif;
      font-weight: 700;
      color: $darkBlue;
    }

    &__text {
      font-weight: 700;
    }
  }

  &__tokens {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem 0;
    justify-content: center;

    &__token {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Libre Franklin', sans-serif;
      margin: 0 1.5rem 0 1.5rem;
      margin-bottom: 1rem;

      &__quote-wrapper {
        width: 300px;
        height: 100px;
        box-shadow: 0px 8px 12px 0px rgba(150, 150, 150, 0.4);
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin-bottom: 1rem;
        padding: 0.5rem;
      }

      &__author-name {
        margin-bottom: 0.5rem;
        font-weight: 700;
      }
      &__author-job {
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
      &__author-age {
        margin-bottom: 0.5rem;
        font-weight: 500;
      }
    }
  }
}

.help {
  position: relative;
  padding: 2rem 0 2rem 2rem;
  margin-bottom: 5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  width: fit-content;

  &__title {
    font-size: 1.5rem;
    color: $darkBlue;
    margin-bottom: 1rem;
    margin-right: 2rem;
  }

  &__text {
    color: $darkBlue;
    margin-bottom: 1rem;
  }

  @include medium-screen {
    &__title {
      margin-bottom: 0rem;
    }

    &__text {
      margin-bottom: 0rem;
    }
  }

  &::after {
    top: 0;
    right: 0;
    content: '';
    height: 100%;
    width: calc(100% + #{$mainHorizontalPadding});
    margin: 0 (-$mainHorizontalPadding) 0 0;
    position: absolute;
    background-color: rgba($green, 0.4);
    z-index: -1;
    border-radius: 20px 0 0px 20px;
    @include medium-screen {
      width: calc(100% + #{$mainHorizontalPaddingMedium});
      margin: 0 (-$mainHorizontalPaddingMedium) 0 0;
    }
    @include large-screen {
      width: calc(100% + #{$mainHorizontalPaddingLarge});
      margin: 0 (-$mainHorizontalPaddingLarge) 0 0;
    }
  }
}
</style>
